import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer className="py-3 text-center">
    <Link to="/impressum" title="Zum Impressum der Website">
      Impressum
    </Link>{" "}
    - &copy; {new Date().getFullYear()}
  </footer>
)

export default Footer
