import React from "react"
import { Link } from "gatsby"

import { Navbar } from "react-bootstrap"

const pages = [
  { title: `portfolio`, url: `/` },
  { title: `sports & body`, url: `/sports-body` },
  { title: `business`, url: `/business` },
  { title: `portraits`, url: `/portraits` },
  { title: `advertising`, url: `/advertising` },
  { title: `lifestyle`, url: `/lifestyle` },
  { title: `more photos`, url: `/more-photos` },
]

const Header = ({ activePageName }) => {
  const activePage = pages.find(entry => entry.title === activePageName)
  console.log(activePageName, activePage)

  return (
    <header>
      <Navbar bg="dark" variant="dark" expand="lg">
        <div className="container">
          <Link className="navbar-brand" to="/">
            Frank Kürzel
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <ul className="navbar-nav ml-auto">
              {pages.map((page, index) => (
                <li
                  className={
                    "nav-item " + (page === activePage ? "active" : "")
                  }
                  key={index}
                >
                  <Link to={page.url} className="nav-link">
                    {page.title}
                  </Link>
                </li>
              ))}
            </ul>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  )
}

export default Header
